import { useQuery } from '@apollo/client';
import { VideoFieldsFragment, WistiaThumbnailQuery } from '../../../client/__generated__/graphql-client-types';
import { VIDEO_PROVIDER_CODE, WISTIA_THUMBNAIL_FALLBACK } from '../../constants/videos';
import { doesWindowExist } from '../../helpers/general-helper/general-helper';
import { WISTIA_THUMBNAIL } from '../../queries/wistia/wistia.queries';

type Thumbnail = {
	thumbnailURL: string;
	imagePreview: string;
	isWistiaFallback: boolean;
	type: 'upload' | 'fetch';
};

export function useVideoThumbnail(video: VideoFieldsFragment): Thumbnail {
	const isWistia = video.streamProviderCode === VIDEO_PROVIDER_CODE.WISTIA;

	const { data } = useQuery<WistiaThumbnailQuery>(WISTIA_THUMBNAIL, {
		skip: !(doesWindowExist() && isWistia),
		variables: { id: video.hashKey }
	});

	const youTubeThumbURL = `http://img.youtube.com/vi/${video.hashKey}/default.jpg`;
	const youTubeImagePreview = `http://img.youtube.com/vi/${video.hashKey}/maxresdefault.jpg`;
	const wistiaThumbURL = data?.wistiaVideoData?.thumbnail?.url || WISTIA_THUMBNAIL_FALLBACK;
	const thumbnailURL = isWistia ? wistiaThumbURL : youTubeThumbURL;
	const isWistiaFallback = Boolean(isWistia && thumbnailURL === WISTIA_THUMBNAIL_FALLBACK);
	const type = isWistiaFallback ? 'upload' : 'fetch';

	return {
		thumbnailURL,
		isWistiaFallback,
		type,
		imagePreview: isWistia ? wistiaThumbURL : youTubeImagePreview
	};
}
