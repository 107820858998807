import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { VideoFieldsFragment } from '../../../__generated__/graphql-client-types';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { EmbeddedVideo } from '../embedded-video/embedded-video.component';
import { Modal } from '../modal/modal.component';

const DEFAULT_DIMENSIONS = {
	maxWidth: 640,
	maxHeight: 360
};

export type VideoModalProps = {
	video: VideoFieldsFragment;
	onClose?: () => void;
};

export const VideoModal: FunctionComponent<PropsWithChildren<VideoModalProps>> = ({ video, onClose, children }) => {
	const modalStyles = { maxWidth: DEFAULT_DIMENSIONS.maxWidth, maxHeight: DEFAULT_DIMENSIONS.maxHeight };
	const [canEmbedVideo, setCanEmbedVideo] = useState(false);

	const close = () => {
		if (onClose) {
			onClose();
		}
	};

	const trigger = children ? <>{children}</> : undefined;

	return (
		<Modal
			title={video.title}
			ariaLabel={video.title}
			triggerElement={trigger}
			onOpen={() => setCanEmbedVideo(true)}
			closeAction={close}
			leftAlignTitle={true}>
			{/* tabIndex 0 is needed here so that this container div gets focus, in order to accept keyboard input */}
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div
				tabIndex={0 /* eslint-disable-line jsx-a11y/no-noninteractive-tabindex */}
				data-testid="video-container"
				className="w-100 h-100"
				onKeyDown={handleKeys(['Escape'], close)}
				style={modalStyles}>
				{canEmbedVideo && <EmbeddedVideo video={video} autoPlay={true} shouldLoadEagerly={true} />}
			</div>
			<p className="tl" style={{ maxWidth: DEFAULT_DIMENSIONS.maxWidth }}>
				{video.description}
			</p>
		</Modal>
	);
};
